/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/swiper-bundle.css";

* {
    overflow-wrap: anywhere;
    font-family: "Montserrat";
}

.errorText {
    font-size: 14px !important;
    text-align: left;
    margin-left: 5px;
}

.errorIcon {
    margin-top: 5px;
}

input {
    box-shadow: none !important;
}

.wo-btn-without-border {
    --border-width: 0;
    --color: auto;
}

.wo-item-without-border {
    --border-width: 0;
    --highlight-background: none;
}

.wo-popover {
    --background: var(--ion-color-dark);

    &::part(content) {
        border-radius: 20px;
        width: 230px;
    }
}

h1,
h2 {
    letter-spacing: 1px !important;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}


.wo-header {
    height: 50px;

    .wo-header-row {
        height: 100%;
        align-items: center;
    }

    .wo-title {
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            text-align: center;
            font-size: 36px;
            font-weight: 700;
            margin: 0;
        }
    }
}

.ion-page {
    background-color: var(--ion-color-light);
}

.wo-no-posts-yet {
    width: 100%;
}

.wo-input-box-shadow {
    box-shadow: 0px 4px 13px rgba(78, 79, 114, 0.2);
}

@media (min-aspect-ratio: 8/9) {
    .hide-footer {
        display: none;
    }
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    src: url('./assets/fonts/Montserrat-Thin.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 100;
    src: url('./assets/fonts/Montserrat-ThinItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 200;
    src: url('./assets/fonts/Montserrat-ExtraLight.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 200;
    src: url('./assets/fonts/Montserrat-ExtraLightItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/Montserrat-Light.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/Montserrat-LightItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/Montserrat-Italic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 500;
    src: url('./assets/fonts/Montserrat-MediumItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 600;
    src: url('./assets/fonts/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/Montserrat-BoldItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/Montserrat-ExtraBold.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 800;
    src: url('./assets/fonts/Montserrat-ExtraBoldItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/Montserrat-Black.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 900;
    src: url('./assets/fonts/Montserrat-BlackItalic.ttf');
}