// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** Primary **/
    --ion-color-primary: #588B8C;
	--ion-color-primary-rgb: 88,139,140;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #4d7a7b;
	--ion-color-primary-tint: #699798;
    
    /** Primary Text **/
    --ion-color-primary-text: #54797B;
	--ion-color-primary-text-rgb: 84,121,123;
	--ion-color-primary-text-contrast: #ffffff;
	--ion-color-primary-text-contrast-rgb: 255,255,255;
	--ion-color-primary-text-shade: #4a6a6c;
	--ion-color-primary-text-tint: #658688;
    
    /** Secondary **/
	--ion-color-secondary: #649596;
	--ion-color-secondary-rgb: 100,149,150;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #588384;
	--ion-color-secondary-tint: #74a0a1;
    
    /** Tertiary **/
    --ion-color-tertiary: #57D0D2;
	--ion-color-tertiary-rgb: 87,208,210;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #4db7b9;
	--ion-color-tertiary-tint: #68d5d7;

    /** Success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45,211,111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** Warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** Danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235,68,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** Dark **/
    --ion-color-dark: #2C2929;
    --ion-color-dark-rgb: 44,41,41;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #272424;
    --ion-color-dark-tint: #413e3e;
    
    /** Medium **/
    --ion-color-medium: #AAAAAA;
	--ion-color-medium-rgb: 170,170,170;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #969696;
	--ion-color-medium-tint: #b3b3b3;
    
    /** Medium Light **/
    --ion-color-medium-light: #EEEEEE;
	--ion-color-medium-light-rgb: 238,238,238;
	--ion-color-medium-light-contrast: #000000;
	--ion-color-medium-light-contrast-rgb: 0,0,0;
	--ion-color-medium-light-shade: #d1d1d1;
	--ion-color-medium-light-tint: #f0f0f0;

    /** Light **/
    --ion-color-light: #FAF8FD;
	--ion-color-light-rgb: 250,248,253;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #dcdadf;
	--ion-color-light-tint: #fbf9fd;

    /** Facebook **/
    --ion-color-facebook: #1976D2;
    --ion-color-facebook-rgb: 25,118,210;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255,255,255;
    --ion-color-facebook-shade: #1668b9;
    --ion-color-facebook-tint: #3084d7;

    /** Black **/
    --ion-color-black: #000000;
    --ion-color-black-rgb: 0,0,0;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255,255,255;
    --ion-color-black-shade: #000000;
    --ion-color-black-tint: #1a1a1a;
}

.ion-color-primary-text {
    --ion-color-base: var(--ion-color-primary-text);
    --ion-color-base-rgb: var(--ion-color-primary-text-rgb);
    --ion-color-contrast: var(--ion-color-primary-text-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-text-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-text-shade);
    --ion-color-tint: var(--ion-color-primary-text-tint);
}

.ion-color-facebook {
    --ion-color-base: var(--ion-color-facebook);
    --ion-color-base-rgb: var(--ion-color-facebook-rgb);
    --ion-color-contrast: var(--ion-color-facebook-contrast);
    --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
    --ion-color-shade: var(--ion-color-facebook-shade);
    --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-black {
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-meidum-light {
    --ion-color-medium-light: #EEEEEE;
	--ion-color-medium-light-rgb: 238,238,238;
	--ion-color-medium-light-contrast: #000000;
	--ion-color-medium-light-contrast-rgb: 0,0,0;
	--ion-color-medium-light-shade: #d1d1d1;
	--ion-color-medium-light-tint: #f0f0f0;
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #16172D;
//     --ion-color-primary-rgb: 22,23,45;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #131428;
//     --ion-color-primary-tint: #2d2e42;

//     --ion-color-secondary: #664BFB;
//     --ion-color-secondary-rgb: 102,75,251;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #5a42dd;
//     --ion-color-secondary-tint: #755dfb;

//     --ion-color-tertiary: #75767C;
//     --ion-color-tertiary-rgb: 117,118,124;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #67686d;
//     --ion-color-tertiary-tint: #838489;

//     --ion-color-alternative: #44A5DC;
//     --ion-color-alternative-rgb: 68,165,220;
//     --ion-color-alternative-contrast: #000000;
//     --ion-color-alternative-contrast-rgb: 0,0,0;
//     --ion-color-alternative-shade: #3c91c2;
//     --ion-color-alternative-tint: #57aee0;

//     --ion-color-success: #2dd36f;
//     --ion-color-success-rgb: 45,211,111;
//     --ion-color-success-contrast: #ffffff;
//     --ion-color-success-contrast-rgb: 255,255,255;
//     --ion-color-success-shade: #28ba62;
//     --ion-color-success-tint: #42d77d;

//     --ion-color-warning: #ffc409;
//     --ion-color-warning-rgb: 255,196,9;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0ac08;
//     --ion-color-warning-tint: #ffca22;

//     --ion-color-danger: #eb445a;
//     --ion-color-danger-rgb: 235,68,90;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #cf3c4f;
//     --ion-color-danger-tint: #ed576b;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #92949c;
//     --ion-color-medium-rgb: 146,148,156;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #808289;
//     --ion-color-medium-tint: #9d9fa6;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #323232;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: transparent;

//     --ion-toolbar-background: transparent;

//     --ion-tab-bar-background: transparent;

//     --ion-card-background: transparent;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #323232;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: transparent;

//     --ion-toolbar-background: transparent;

//     --ion-tab-bar-background: transparent;

//     --ion-card-background: transparent;
//   }
// }
